/** @deprecated use `@wss/error-tracking/sentry` instead */
import { env } from "./Environment";
import Store from "./Store";

const sentryUrls = {
  loc: null,
  dev: "https://c984214f46614ded89dd5ec7cb24ba7f@sentry.dev.clarkinc.biz/446",
  test: "https://4caf865843954418900c2701e7b69762@sentry.test.clarkinc.biz/335",
  prod:
    "https://89474327c0ff489ab6d86bc5dac07ee1@www.webstaurantstore.com/sentry/299",
};

import(/* webpackChunkName: "vendors~sentry-browser" */ "@sentry/browser").then(
  ({ init, configureScope }) => {
    // Change the line below to `const url = sentryUrls.dev` to enable local logging.
    const url = sentryUrls[env.current];

    if (url) {
      init({
        dsn: url,
        beforeSend(event, hint) {
          // Removes error caused by ad blockers
          if (event.exception?.values?.[0]) {
            const error = event.exception.values[0];
            const frame = error.stacktrace?.frames?.[0];
            if (frame?.filename?.includes("s.pinimg.com")) {
              return null;
            }
          }

          return event;
        },
      });

      configureScope((scope) => {
        scope.setUser({ id: Store.getState().user.index });
      });
    }
  }
);
